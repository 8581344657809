import { useOutsideClick } from "@chakra-ui/hooks";
import {
  Box,
  ChakraProvider,
  extendTheme,
  useDisclosure,
} from "@chakra-ui/react";
import type { CalendarDate, CalendarValues } from "datepicker";
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarDefaultTheme,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarWeek,
} from "datepicker";
import dayjs from "dayjs";
import React from "react";

export const theme = extendTheme(CalendarDefaultTheme, {
  components: {
    // style props ref - https://github.com/preventure/datepicker/blob/ba199f819b32cd5afdb0b563c14a7f5eec8e7a28/src/theme/day.ts
    CalendarDay: {
      baseStyle: {
        h: 6,
        px: 2,
        rounded: "none",
        fontSize: "sm",
        color: "black",
        // bgColor: "blue.400",
        _hover: {
          bgColor: "blue.200",
        },
        _focus: {
          outline: "none",
          bgColor: "blue.400",
        },
      },
      variants: {
        selected: {
          bgColor: "blue.400",
          color: "white",
          _hover: {
            bgColor: "blue.300",
          },
        },
        range: {
          bgColor: "blue.200",
          color: "white",
          _hover: {
            bgColor: "blue.100",
          },
          _disabled: {
            _hover: {
              bgColor: "blue.300",
            },
          },
        },
        outside: {
          color: "gray.300",
        },
        today: {
          bgColor: "blue.100",
          _hover: {
            bgColor: "blue.200",
          },
        },
      },
    },
  },
});

function isRangeSelection(object: any): object is CalendarValues {
  return "start" in object || "end" in object;
}

export const DateRangePickerCalendar = ({
  startDate,
  endDate,
  displayMonths,
  onChange,
}: {
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  displayMonths?: number;
  onChange?: (dates: CalendarValues) => void;
}) => {
  const parseInputDate = (dateLike: string | null | undefined) =>
    dateLike ? dayjs(dateLike, "YYYY-MM-DD").toDate() : undefined;

  const firstDate = parseInputDate(startDate);
  const secondDate = parseInputDate(endDate);

  const [dates, setDates] = React.useState<CalendarValues>({
    start: firstDate,
    end: secondDate,
  });

  const { isOpen, onClose } = useDisclosure();

  const calendarRef = React.useRef(null);

  const MONTHS = displayMonths || 1;

  const handleSelectDate = (dates: CalendarValues | CalendarDate) => {
    if (!isRangeSelection(dates)) {
      return;
    }

    if (dates.start && dates.end && dates.start > dates.end) {
      return;
    }

    setDates(() => dates);

    if (onChange) {
      onChange(dates);
    }
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  return (
    <ChakraProvider theme={theme}>
      <Calendar
        value={dates}
        onSelectDate={handleSelectDate}
        months={MONTHS}
        allowOutsideDays
        highlightToday
      >
        <Box position="relative">
          <CalendarControls>
            <CalendarPrevButton />
            <CalendarNextButton />
          </CalendarControls>

          <CalendarMonths>
            {[...Array(MONTHS).keys()].map(m => (
              <CalendarMonth key={m} month={m}>
                <CalendarMonthName />
                <CalendarWeek />
                <CalendarDays />
              </CalendarMonth>
            ))}
          </CalendarMonths>
        </Box>
      </Calendar>
    </ChakraProvider>
  );
};
